<template>
    <div>
        <h1>Page can be shown here...</h1>
    </div>
</template>

<script>
    export default {
        name: "PageRender",
        data() {
            return{
                
            }
        }
    }
</script>